import React, { useState } from 'react';
import {  Modal } from "react-bootstrap";
import $ from "jquery";
import { myConfig } from "../../../config";
import validator from 'validator';

const axios = require("axios");

export default function Contact({showContact, handleContactClose, authorEmail}){

    const [showContactEmailAlert, setShowContactEmailAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertColor, setAlertColor] = useState('');

    var qs = require("qs");

	const sendContactEmail = (e) => {
        var hostname = localStorage.getItem("hostname");
		if ($('#CAAuthorEmail').val() && $('#CAEmail').val()) {
            //if (/^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[A-Za-z]+$/.test($('#CAEmail').val())) {
            if (validator.isEmail($('#CAEmail').val())) {
                let emailUserName = $('#CAEmail').val().split("@")[0];
                if (/^(?![0-9]+$)[a-zA-Z0-9\-._]+$/.test(emailUserName)) {
                    setShowContactEmailAlert(false);
                    let data = qs.stringify({
                        contactAuthorEmail: $('#CAAuthorEmail').val(),
                        contactPersonEmail: $('#CAEmail').val(),
                        contactMessage: $('#CAMsg').val(),
                        hostname: hostname
                    });

                    axios({
                        method: "POST",
                        url: myConfig.apiUrl + "contactAuthor.php",
                        data: data,
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            Accept: "application/json",
                        },
                    })
                    .then(function (response) { // Success
                        //console.log(response);
                        if (response.data == 1) {
                            setAlertText('Email sent successfully');
                            setAlertColor('alert-success');
                        } else {
                            setAlertText('Email sent failed');
                            setAlertColor('alert-danger');
                        }
                        setShowContactEmailAlert(true);
                        
                    }).catch(function (error) {
                        console.log(error);
                    });
                } else {
                    // setAlertText('Must include one alphabet');
                    // setAlertColor('alert-warning');
                    setAlertText('Email is not valid');
                    setAlertColor('alert-danger');
                    setShowContactEmailAlert(true);
                }
            } else {
                setAlertText('Email is not valid');
                setAlertColor('alert-danger');
                setShowContactEmailAlert(true);
            }
		} else {
			setAlertText('Please provide all details');
			setAlertColor('alert-warning');
			setShowContactEmailAlert(true);
		}
	}

    return(
        <Modal className="dwnp-modal contactdwnp" show={showContact} onHide={handleContactClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h2>Contact Author</h2>
                {(showContactEmailAlert) ? 
                    <div className={"alert " + alertColor} id="sentEmailAlert" role="alert">
                        {alertText}
                    </div>
                : ''}
                <div className="dp-from">
                        <div className="from-group">
                            <label>Your Email Address</label>
                            <input
                                type="text"
                                placeholder="Type your email address"
                                className="form-control"
                                id="CAEmail"
                            />
                        </div>
                        <input type="hidden" id="CAAuthorEmail" value={authorEmail} />
                        <div className="from-group">
                            <label>Your Messege</label>
                            <textarea placeholder="Type your message" id="CAMsg" className="form-control"/>
                        </div>
                        <button type="button" onClick={(e) => {
                            sendContactEmail(e);
                        }}>
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            Send
                        </button>
                        <p>(Email delivery scheduled every 2 hours)</p>
                </div>
            </Modal.Body>
        </Modal>
    );
}