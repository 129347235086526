import React, { useState, useEffect } from "react";
import "./Index.scss";
import { myConfig } from "../../config";
import { useSelector } from 'react-redux';

const Index = (props) => {

	// Clearing localstorage in home page
	localStorage.removeItem('openFilters');
	localStorage.removeItem('searchValue');
	localStorage.removeItem('dateValue');
	localStorage.removeItem('authorValue');

	const themes = useSelector((state) => { return state.rootReducer.themes.themes.themes;});
	var hostname = localStorage.getItem("hostname");

	return (
      	<div className="indexRoot">
			
			{/* <ScrollArea
				speed={0.8}
				className="area"
				contentClassName="content"
				horizontal={false}
			> */}
				<div className="indexRow">
					{
						themes.map((theme) => {
							var backGroundStyle = '';
							//console.log(props.theme_background_image);
							if (props.theme_background_image == 1) {
								var backgrdImage = '';
								var backgroundImage = theme.theme_background_image;
								if (backgroundImage) {
									backgrdImage = myConfig.externalFileUrl + backgroundImage;
									//console.log(backgrdImage);
									backGroundStyle = {
										backgroundImage: "url("+backgrdImage+")", 
									}
								} else {
									backGroundStyle = {
										background: theme.color_code
									}
								}
							} else {
								backGroundStyle = {
									background: theme.color_code
								}
							}
							var href = "/posters?tid=" + theme.id;
							if (props.showSingleThemePoster == 1) {
								if (theme.singlePoster == 1) {
									var posterShareLink = window.location.href + "posters?tid=" + theme.id;
									var htmlURL = myConfig.http + hostname + "" + theme.singleHtmlFile;
									var posterLink = btoa(htmlURL);
									if (posterShareLink.indexOf("?") > -1) {
										posterShareLink += "&preview=" + posterLink + theme.singleLink;
									} else {
										posterShareLink += "?preview=" + posterLink + theme.singleLink;
									}
									href = posterShareLink;
								}
							}

							return (
								<a href={href} className="incol" style={backGroundStyle}>
									<p>{theme.custom_label}</p>
								</a>
							)

						})
					}
				</div>
			{/* </ScrollArea> */}
		</div>
    );
}

export default Index;
