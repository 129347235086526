import React, { Component, useState, useEffect, useRef } from "react";
import "./poster.scss";
import ScrollArea from "react-scrollbar";
import Select from "react-select";
import { myConfig } from "../../config";
import "react-pure-modal/dist/react-pure-modal.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import PosterSkeleton from "../../skeletons/PosterSkeleton";
import FadeIn from "react-fade-in";
import { useSelector, useDispatch } from "react-redux";
import { chnageFilterData, setPostersReviewsData } from "../../actions/index";
import $ from "jquery";
import { Button, Modal } from "react-bootstrap";
import { AiOutlineDown } from "react-icons/ai";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import { TailSpin } from "react-loader-spinner";
import Rating from "react-rating";
import validator from "validator";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import InfiniteScroll from "react-infinite-scroller";

import Contact from "../../components/poster-actions/contact/Contact";

import Share from "../../components/poster-actions/share/Share";
import "../../components/poster-actions/share/share.module.scss";
import PosterPdf from "./PosterPdf";

const axios = require("axios");

const Poster = (props) => {
  const dispatch = useDispatch();
  var qs = require("qs");

  var whitelabel = props.whiteLabel;

  const footerRef = useRef(null);
  const pdfPreviewRef = useRef(null);
  const pptPreviewRef = useRef(null);
  const [state, setState] = useState({});
  const [modal, setModal] = useState(false);
  const [ratingStarColor, setSettingStarColor] = useState("");
  const [isNotLoaded, setIsNotLoaded] = useState(true);
  const [posters, setPosters] = useState([]);
  const [posterCount, setPosterCount] = useState();
  const [viewCountIncreased, setViewCountIncreased] = useState(false);
  const [sortingData, setSortingData] = useState("byword");
  const [singlePosterViewCount, setSinglePosterViewCount] = useState(1);
  const [posterId, setPosterId] = useState(0);
  const [showPreviewPosterDownload, setShowPreviewPosterDownload] = useState(0);
  const [showPreviewPosterRating, setShowPreviewPosterRating] = useState("0");
  const [showPreviewPosterAbstract, setShowPreviewPosterAbstract] =
    useState("none");
  const [posterAllowDownload, setPosterAllowDownload] = useState("1");
  const [showPreviewPosterShare, setShowPreviewPosterShare] = useState(0);
  const [Overviewmodal, ovsetModal] = useState(false);
  const [loadMoreButtonCalled, setLoadMoreButtonCalled] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [previewPosterShareLink, setPreviewPosterShareLink] = useState("");

  const [alertText, setAlertText] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [showEmailAlert, setShowEmailAlert] = useState(false);

  const [oPosterId, setOPosterId] = useState(""); // poster_id,
  const [oPaperId, setOPaperId] = useState(""); // paper_id,
  const [oThemeId, setThemeID] = useState(""); // theme_id,
  const [oAuthorName, setOAuthorName] = useState(""); // author_name,
  const [oThemeName, setOThemeName] = useState(""); // theme_name,
  const [oPosterTitle, setOPosterTitle] = useState(""); // response.data.posterDetails.title,
  const [oPosterCoAuthors, setOPosterCoAuthors] = useState(""); // response.data.posterDetails.coAuthors,
  const [oAuthorEmail, setOAuthorEmail] = useState(""); // response.data.posterDetails.authorEmail,
  const [oAuthorImage, setOAuthorImage] = useState("");
  const [oAuthorBio, setOAuthorBio] = useState("");
  const [oCustomData, setOCustomData] = useState({}); // response.data.customData,
  const [oSimilarPosters, setOSimilarPosters] = useState({}); // response.data.similarPosters,
  const [oPostersBySameAuthor, setOPostersBySameAuthor] = useState({}); // response.data.postersBySameAuthor
  const [oAbstractData, setOAbstractData] = useState({
    type: "none",
    data: "",
  });
  const [totalViews, setTotalViews] = useState(1);

  const [sessionRoomDrop, setSessionRoomDrop] = useState(false);

  // const [pdfExists, setPdfExists] = useState(false);
  const [pdfURL, setPdfURL] = useState("");

  //Get Event ID
  var eventId = atob(localStorage.getItem("eid"));

  // Get URL data
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  var theme_id = params.get("tid");
  var preview_poster_id = params.get("preview");
  var oThemeUrl = params.get("theme");
  var oPosterIdUrl = params.get("posterId");
  var oAuthorUrl = params.get("author");
  var indiDownload = params.get("indiDownload");
  var totalViewsUrl = params.get("tv");

  // Getting Redux Data
  var themesData = useSelector((state) => {
    return state.rootReducer.themes.themes.themes;
  });

  var storeFilterData = useSelector((state) => {
    return state.rootReducer.filters;
  });

  var programDates = useSelector((state) => {
    return state.rootReducer.programdates;
  });

  var eConfig = useSelector((state) => {
    // return state.rootReducer.themes.themes.themes;
    return state.rootReducer.eventsettings?.eventsettings?.eventsettings;
  });

  var pdfExists = eConfig?.posterFileAs?.toLowerCase() == "pdf";

  // Local storage data
  var esettings = localStorage.getItem("esettings");
  var settingsData = JSON.parse(esettings);

  var themeName = localStorage.getItem("themeName") ?? "All Theme Posters";

  // Select Topic
  var options = [{ value: "all", label: "All Theme Posters" }];
  for (let i = 0; i < themesData.length; i++) {
    options.push({ value: themesData[i]["id"], label: themesData[i]["label"] });
    if (themesData[i]["id"] === theme_id) {
      localStorage.setItem("themeName", themesData[i]["label"]);
    }
  }

  // Sorting
  const sortOptions = [
    { value: "title", label: "Alphabetical Order" },
    { value: "rating", label: "Most Rated" },
    { value: "viewCount", label: "Most Viewed" },
  ];
  var defaultSortDropdown = { value: "title", label: "Alphabetical Order" };
  if (props.defaultSort == "get-top-rated") {
    defaultSortDropdown = { value: "rating", label: "Most Rated" };
  } else if (props.defaultSort == "get-most-viewed") {
    defaultSortDropdown = { value: "viewCount", label: "Most Viewed" };
  }

  var hostname = localStorage.getItem("hostname");
  //   console.log(hostname);

  var urlsearchbar = params.get("s"); // search bar data when coming from index page
  var urlauthor = params.get("a"); // author dropdown when coming from index page
  var urldate = params.get("d"); // date when coming from index page
  var urlroom = params.get("r"); // room when coming from index page
  var urlsession = params.get("n"); // session when coming from index page

  if (urlauthor) {
    dispatch(chnageFilterData(null, urlauthor));
    window.history.replaceState({}, document.title, "/" + "posters?tid=all");
  }

  if (urlsearchbar) {
    dispatch(chnageFilterData(urlsearchbar));
    window.history.replaceState({}, document.title, "/" + "posters?tid=all");
  }

  if (urldate) {
    dispatch(chnageFilterData(null, null, urldate, urlroom, urlsession));
    // dispatch(chnageFilterData(null, null, urldate));
    window.history.replaceState({}, document.title, "/" + "posters?tid=all");
  }
  var showTag = storeFilterData?.filters?.posterDate != undefined ? 1 : 0;
  // Poster API
  useEffect(() => {
    setShowPreviewPosterShare(settingsData.posterShare);
    setShowPreviewPosterDownload(settingsData.posterDownload);
    setShowPreviewPosterRating(settingsData.posterRating);
    setTotalViews(totalViews);
    if (indiDownload == 0) {
      setPosterAllowDownload("" + indiDownload);
    }

    if (totalViewsUrl) {
      setTotalViews(totalViewsUrl);
    }

    // window.addEventListener('scroll', handleScroll);
    var filterdata = "";
    if (preview_poster_id) {
      //console.log(document.getElementById("getdata"));
      if (!pdfExists) {
        document.getElementById("getdata").src = "";
        document.getElementById("getdata").src = atob(preview_poster_id);
      }
      getOverView(oPosterIdUrl, oAuthorUrl, oThemeUrl);
      $("#mask").fadeTo(500, 1);
      $("#popup").show();
    }

    // If filters applied then no pagination
    if (Object.keys(storeFilterData).length == 1) {
      setPageNumber(0);
    }

    filterdata = qs.stringify({
      searchData: storeFilterData, // represents search bar text or author dropdown
      theme_id: theme_id,
      sorting: sortingData,
      // room_id: urlroom,
      // session: urlsession,
      // date: urldate,
      room_id: storeFilterData?.filters?.posterRoom,
      session: storeFilterData?.filters?.posterSessionName,
      date: storeFilterData?.filters?.posterDate,
      hostname: hostname,
      page: 0, //pageNumber
    });
    axios({
      method: "POST",
      url: myConfig.apiUrl + "posters.php",
      data: filterdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // Success
        // console.log(222, response.data);

        let rawposters = [];
        if (response.data.posters.length > 0) {
          rawposters = response.data.posters;
        }
        setPosters(rawposters);
        setIsNotLoaded(false);
        setPosterCount(response.data.totalPosterCount);
        setViewCountIncreased(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    return () => {
      setState({});
    };
  }, [storeFilterData, sortingData, viewCountIncreased]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (pdfExists) {
        window.addEventListener("resize", handleResize);
        window.addEventListener("load", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
          window.removeEventListener("load", handleResize);
        };
      } else {
        window.addEventListener("resize", ppthandleResize);
        window.addEventListener("load", ppthandleResize);
        return () => {
          window.removeEventListener("resize", ppthandleResize);
          window.removeEventListener("load", ppthandleResize);
        };
      }
    }
  }, [window.innerHeight]);

  const handleResize = (e) => {
    if (
      typeof window !== "undefined" &&
      footerRef.current !== null &&
      pdfPreviewRef.current !== null
    ) {
      const height = window.innerHeight;
      const restHeight = height - footerRef.current.offsetHeight;
      pdfPreviewRef.current.style.height = `${restHeight}px`;
    }
  };

  const ppthandleResize = (e) => {
    if (
      typeof window !== "undefined" &&
      footerRef.current !== null &&
      pptPreviewRef.current !== null
    ) {
      const height = window.innerHeight;
      const footerHeight = footerRef.current.offsetHeight;
      // if (height < 767) {
      //   pptPreviewRef.current.style.minHeight = `calc(100vh - ${footerHeight}px + 60px)`
      // }
      // else if (height < 992) { pptPreviewRef.current.style.minHeight = `calc(100vh) - 60px` }
      // else {
      // }
      pptPreviewRef.current.style.minHeight = `calc(${height}px)`;
      pptPreviewRef.current.style.padding = `0 0 ${footerHeight}px 0`;
      // console.dir(pptPreviewRef.current.contentWindow)
    }
  };

  // Sorting
  const changeSort = (sortwith) => {
    setSortingData(sortwith);
  };

  // Increase view count
  const increaseViewCount = (posterId) => {
    let data = qs.stringify({
      posterId: posterId,
      hostname: hostname,
    });
    axios({
      method: "POST",
      url: myConfig.apiUrl + "increaseViewCount.php",
      data: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // handle success
        //setViewCountIncreased(true);
        setViewCountIncreased(true);
        if (response > 0) {
          setSinglePosterViewCount(response);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  // CLose Preview Modal
  const closePreviewModal = () => {
    localStorage.removeItem("pdfURL");
    document.getElementsByTagName("body")[0].style = "";
    setSettingStarColor("");
    //document.getElementsByClassName("ratingStar").style = 'color: red;'; //#ccc
    $("#popup").fadeOut();
    $("#mask").hide();
    if (!pdfExists) {
      document.getElementById("getdata").src = "";
    }
    window.history.replaceState(
      {},
      document.title,
      "/" + "posters?tid=" + theme_id
    );
  };

  // Open Preview Modal with data
  const preview = (event) => {
    if (event.target.dataset.htmlsrc != "") {
      var url = window.location.href;
      // if (eventId == myConfig.s3EventId) { // pulling from S3
      // 	var htmlURL =  myConfig.s3HostName + "" + event.target.dataset.htmlsrc;
      // } else {

      if (!pdfExists) {
        var htmlURL =
          myConfig.http + hostname + "" + event.target.dataset.htmlsrc;
      }

      //}
      // console.log(131, myConfig);
      var posterLink = btoa(htmlURL);
      // Overview funcclosemask
      let oPosterId = event.target.dataset.posterid;
      let oThemeName = encodeURIComponent(event.target.dataset.theme);
      let oAuthorName = event.target.dataset.author;
      let individualDownload = event.target.dataset.individualdownload;
      let totalPosterViews = event.target.dataset.totalviews;

      if (url.indexOf("?") > -1) {
        url +=
          "&preview=" +
          posterLink +
          "&theme=" +
          oThemeName +
          "&posterId=" +
          oPosterId +
          "&author=" +
          oAuthorName +
          "&indiDownload=" +
          individualDownload +
          "&tv=" +
          totalPosterViews;
      } else {
        url +=
          "?preview=" +
          posterLink +
          "&theme=" +
          oThemeName +
          "&posterId=" +
          oPosterId +
          "&author=" +
          oAuthorName +
          "&indiDownload=" +
          individualDownload +
          "&tv=" +
          totalPosterViews;
      }
      document.getElementsByTagName("body")[0].style = "overflow: hidden;";
      //var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?arg=1';
      window.history.pushState({ path: url }, "", url);

      if (!pdfExists) {
        document.getElementById("getdata").src = htmlURL;
      }

      setPreviewPosterShareLink(url);
      getOverView(oPosterId, oAuthorName, oThemeName);
      let pbsa = JSON.parse(
        localStorage.getItem("overViewDetails")
      ).oPostersBySameAuthor;
      let sp = JSON.parse(
        localStorage.getItem("overViewDetails")
      ).oSimilarPosters;
      setOPostersBySameAuthor(pbsa);
      setOSimilarPosters(sp);

      $("#mask").fadeTo(500, 1);
      $("#popup").show();
    } else {
      alert("Poster not available");
    }
  };

  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowEmailAlert(false);
  };
  const handleShareClose = () => setShowShare(false);
  const handleShow = () => setShow(true);
  const handleShareShow = () => setShowShare(true);
  const handleContactShow = () => setShowContact(true);

  const [showView, setShowView] = useState(false);

  const handleViewClose = () => setShowView(false);
  const handleViewShow = () => setShowView(true);

  // Rate poster
  const ratePoster = (e) => {
    // let ratingandviews = $(e.target).text();
    // let rawdata = ratingandviews.split("-");
    // let rating = rawdata[0];
    // let totalviews = rawdata[1];

    let rating = e;
    let totalviews = $("#singlePosterViews").val();

    if (oPosterId > 0) {
      let data = qs.stringify({
        posterId: posterId,
        rating: rating,
        totalViews: totalviews,
        hostname: localStorage.getItem("hostname"),
      });
      axios({
        method: "POST",
        url: myConfig.apiUrl + "saveRating.php",
        data: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          // handle success
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  };

  const getOverView = (poster_id, author_name, theme_name) => {
    let filterdata = qs.stringify({
      // represents search bar text or author dropdown
      posterId: poster_id,
      hostname: hostname,
      author_name: author_name,
      theme_name: theme_name,
    });

    axios({
      method: "POST",
      url: myConfig.apiUrl + "getPosterDetails.php",
      data: filterdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // Success

        let overViewDetails = {
          oPosterId: poster_id,
          oAuthorName: author_name,
          oThemeName: theme_name,
          oPaperId: response.data.posterDetails.paperId,
          oThemeId: response.data.posterDetails.theme_id,
          oPosterTitle: response.data.posterDetails.title,
          oPosterCoAuthors: response.data.posterDetails.coAuthors,
          oAuthorEmail: response.data.posterDetails.authorEmail,
          oAuthorImage: response.data.posterDetails.authorImage,
          oAuthorBio: response.data.posterDetails.authorBio,
          oCustomData: response.data.customData,
          oSimilarPosters: response.data.similarPosters,
          oPostersBySameAuthor: response.data.postersBySameAuthor,
          oAbstractData: response.data.abstractData,
        };
        localStorage.setItem(
          "overViewDetails",
          JSON.stringify(overViewDetails)
        );
        let pDetails = JSON.parse(localStorage.getItem("overViewDetails"));
        setOPosterId(pDetails.oPosterId);
        setOPaperId(pDetails.oPaperId);
        setOAuthorName(pDetails.oAuthorName);
        setOThemeName(pDetails.oThemeName);
        setThemeID(pDetails.oThemeId);
        setOPosterTitle(pDetails.oPosterTitle.replace(/(<([^>]+)>)/gi, ""));
        setOPosterCoAuthors(pDetails.oPosterCoAuthors);
        setOAuthorEmail(pDetails.oAuthorEmail);
        setOAuthorBio(pDetails.oAuthorBio);
        setOCustomData(pDetails.oCustomData);
        setOSimilarPosters(pDetails.oSimilarPosters);
        setOPostersBySameAuthor(pDetails.oPostersBySameAuthor);
        setOAbstractData(pDetails.oAbstractData);

        let OAImg = "";
        if (pDetails.oAuthorImage != "" && pDetails.oAuthorImage != null) {
          OAImg = myConfig.externalFileUrl + pDetails.oAuthorImage;
        } else {
          OAImg = "/images/profile.png";
        }
        setOAuthorImage(OAImg);
        setShowPreviewPosterAbstract();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const showOverView = () => {
    $("#ovModal").fadeIn();
  };

  const closeOverViewModal = () => {
    $("#ovModal").fadeOut();
  };

  const showAbstract = () => {
    $("#abModal").fadeIn();
  };

  const closeAbstractModal = () => {
    $("#abModal").fadeOut();
  };

  const sendEmail = (e) => {
    //alert(oPosterId);
    if ($("#SEPersonName").val() && $("#SEPersonEmail").val()) {
      //if (/^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-_]+\.[A-Za-z]+$/.test($("#SEPersonEmail").val())) {
      if (validator.isEmail($("#SEPersonEmail").val())) {
        let emailUserName = $("#SEPersonEmail").val().split("@")[0];
        if (/^(?![0-9]+$)[a-zA-Z0-9.-_]+$/.test(emailUserName)) {
          setShowEmailAlert(false);
          let data = qs.stringify({
            posterId: oPosterId,
            personName: $("#SEPersonName").val(),
            personEmail: $("#SEPersonEmail").val(),
          });

          //console.log(data);
          axios({
            method: "POST",
            url: myConfig.apiUrl + "sendEmail.php",
            data: data,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Accept: "application/json",
            },
          })
            .then(function (response) {
              // Success
              // console.log(response);

              if (response != "Invalid Email Address") {
                if (response.data == 1) {
                  setAlertText("Email sent successfully");
                  setAlertColor("alert-success");
                } else {
                  setAlertText("Email sent failed");
                  setAlertColor("alert-danger");
                }
                // $("#SEPersonName").val('');
                // $("#SEPersonEmail").val('');
              } else {
                setAlertText("Email is not valid");
                setAlertColor("alert-danger");
                setShowEmailAlert(true);
              }
              setShowEmailAlert(true);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          // setAlertText('Must include one alphabet');
          // setAlertColor('alert-warning');
          setAlertText("Email is not valid");
          setAlertColor("alert-danger");
          setShowEmailAlert(true);
        }
      } else {
        setAlertText("Email is not valid");
        setAlertColor("alert-danger");
        setShowEmailAlert(true);
      }
    } else {
      setAlertText("Please provide all details");
      setAlertColor("alert-warning");
      setShowEmailAlert(true);
    }
  };

  var startTimeArr = [];
  var endTimeArr = [];
  if (window.location.search.includes("&preview"))
    document.body.style.overflow = "hidden";
  else document.body.style.overflow = "auto";
  return (
    <div className="posterRoot">
      <div className="container">
        <div className="postsrch" id="tanmoy">
          <h2
            // id="tanmoy"

            style={
              eConfig?.textColorTheme == 0
                ? { color: "#FFF" }
                : { color: "#3b3b3b" }
            }
          >
            {posterCount} Posters
          </h2>

          <div className="poscol">
            <p
              style={
                eConfig?.textColorTheme == 0
                  ? { color: "#FFF" }
                  : { color: "#3b3b3b" }
              }
            >
              Topic
            </p>
            <Select
              placeholder={themeName}
              options={options}
              onChange={(event) => {
                localStorage.removeItem("openFilters");
                localStorage.removeItem("dateValue");
                localStorage.removeItem("authorValue");
                localStorage.removeItem("searchvalue");
                window.location.href = "/posters?tid=" + event.value;
              }}
              className="modselect2 posterSelects"
              // styles={{ color: "white" }}
              // style={
              //   eConfig?.textColorTheme === "1"
              //     ? { color: "#FFF", border: "1px solid #FFF" }
              //     : { color: "#3b3b3b", borderColor: "#3b3b3b" }
              // }
            />
          </div>
          <div className="poscol poscolsc">
            <p
              style={
                eConfig?.textColorTheme == 0
                  ? { color: "#FFF" }
                  : { color: "#3b3b3b" }
              }
            >
              Sort by
            </p>
            <Select
              defaultValue={defaultSortDropdown}
              options={sortOptions}
              onChange={(event) => {
                setPosters([]);
                changeSort(event.value);
              }}
              className="modselect2 posterSelects"
              // styles={{ color: "white" }}
              styles={
                eConfig?.textColorTheme == 0
                  ? { color: "#FFF" }
                  : { color: "#3b3b3b" }
              }
            />
          </div>
        </div>
        <FadeIn>
          <div className="posterrow">
            {isNotLoaded ? (
              <>
                <PosterSkeleton />
                <PosterSkeleton />
                <PosterSkeleton />
                <PosterSkeleton />
                <PosterSkeleton />
                {/* <TailSpin type="TailSpin" id="loader" color="#00BFFF" height={80} width={80}/> */}
              </>
            ) : (
              posters.map((poster) => {
                var thumbimg = myConfig.http + hostname + "/" + poster.thumb;

                var posterShareLink = window.location.href;
                var authorImage = "/images/profile.png";
                if (
                  poster.authorImage &&
                  poster.authorImage != "" &&
                  poster.authorImage != "profile.png"
                ) {
                  authorImage = myConfig.externalFileUrl + poster.authorImage;
                }
                // if (eventId == myConfig.s3EventId) { // pulling from S3
                // 	var htmlURL =  myConfig.s3HostName + "" + poster.htmlfile;
                // } else {
                if (!pdfExists) {
                  var htmlURL = myConfig.http + hostname + "" + poster.htmlfile;
                }
                //}
                var posterLink = btoa(htmlURL);
                if (posterShareLink.indexOf("?") > -1) {
                  posterShareLink +=
                    "&preview=" +
                    posterLink +
                    "&theme=" +
                    poster.theme_label +
                    "&posterId=" +
                    poster.posterId +
                    "&author=" +
                    poster.author +
                    "&indiDownload=" +
                    poster.allow_download +
                    "&tv=" +
                    poster.viewCount;
                } else {
                  posterShareLink +=
                    "?preview=" +
                    posterLink +
                    "&theme=" +
                    poster.theme_label +
                    "&posterId=" +
                    poster.posterId +
                    "&author=" +
                    poster.author +
                    "&indiDownload=" +
                    poster.allow_download +
                    "&tv=" +
                    poster.viewCount;
                }

                // console.log(posterShareLink);

                let zip = (a1, a2) =>
                  a1.map((x, i) => [x.slice(0, -3) + "-" + a2[i].slice(0, -3)]);
                let poster_start_time = poster?.time_start?.split(" | ");
                let poster_end_time = poster?.time_end?.split(" | ");
                let poster_time = zip(poster_start_time, poster_end_time);
                return (
                  <div className="postercol">
                    <LazyLoadImage
                      effect="blur"
                      onClick={(event) => {
                        if (event.target.dataset.htmlsrc != "") {
                          setModal(true);
                          let posterId = event.target.dataset.posterid;
                          increaseViewCount(posterId);
                          setPosterId(poster.posterId);
                          setPosterAllowDownload("" + poster.allow_download);
                          handleViewShow();

                          if (pdfExists) {
                            // console.log("xxx", typeof(eConfig?.posterFileAs?.toLowerCase()))
                            // setPdfExists(true);
                            const pdfhost = myConfig?.http + hostname + "";
                            const filePath = poster?.htmlfile;

                            var pdfURL = pdfhost + filePath;
                            localStorage.setItem("pdfURL", `${pdfURL}`);
                            setPdfURL(pdfURL);
                          }

                          preview(event);
                        } else {
                          alert("Poster not available");
                        }
                      }}
                      data-posterid={poster.posterId}
                      data-htmlsrc={poster.htmlfile}
                      src={thumbimg}
                      data-share={settingsData.posterShare}
                      data-download={settingsData.posterDownload}
                      data-theme={poster.theme_label}
                      data-author={poster.author}
                      data-individualdownload={poster.allow_download}
                      data-totalviews={poster.viewCount}
                    />

                    <div className="postconholder">
                      {poster.venue_name && (
                        <div>
                          {showTag == 1 ? (
                            <div
                              className="poster-drop"
                              onClick={() => {
                                setSessionRoomDrop(!sessionRoomDrop);
                                var key = "postersession" + poster.posterId;
                                $("#" + key).css(
                                  "display",
                                  !sessionRoomDrop ? "block" : "none"
                                );
                              }}
                              id={poster.posterId}
                              data-SessionRoomDrop={sessionRoomDrop}
                            >
                              {poster_time.length > 2
                                ? poster_time.slice(0, 2).map((t, key) => (
                                    <>
                                      {t}
                                      {poster_time.slice(0, 2).length - 1 !==
                                        key && <> | </>}
                                      {poster_time.slice(0, 2).length - 1 ==
                                        key && <> | ...</>}
                                    </>
                                  ))
                                : poster_time.map((t, key) => (
                                    <>
                                      {t}
                                      {poster_time.length - 1 !== key && (
                                        <> | </>
                                      )}
                                    </>
                                  ))}

                              <i className="las la-angle-down"></i>
                            </div>
                          ) : (
                            ""
                          )}

                          <div
                            id={"postersession" + poster.posterId}
                            style={
                              // sessionRoomDrop
                              // ? { display: "block" }
                              // :
                              { display: "none" }
                            }
                          >
                            <div className="poster-drop-down">
                              <div style={{ color: "#C5C5C5" }}>Session:</div>
                              <div>
                                {poster.session_label.split(" | ").map((s) => (
                                  <div style={{ fontSize: "14px" }}>{s}</div>
                                ))}
                              </div>
                              <div style={{ color: "#C5C5C5" }}>Room:</div>
                              <div>
                                {poster.venue_name.split(" | ").map((r) => (
                                  <div style={{ fontSize: "14px" }}>{r}</div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* <span className="tndtag">
								<img src="/images/trndicon.svg" /> TRENDING
							</span> */}

                      <p
                        onClick={(event) => {
                          if (event.target.dataset.htmlsrc != "") {
                            setModal(true);
                            let posterId = event.target.dataset.posterid;
                            increaseViewCount(posterId);
                            setPosterId(poster.posterId);
                            setPosterAllowDownload("" + poster.allow_download);
                            handleViewShow();

                            if (pdfExists) {
                              const pdfhost = myConfig?.http + hostname + "";
                              const filePath = poster?.htmlfile;

                              let pdfURL = pdfhost + filePath;
                              localStorage.setItem("pdfURL", `${pdfURL}`);
                              setPdfURL(pdfURL);
                            }
                            preview(event);
                          } else {
                            alert("Poster not available");
                          }
                        }}
                        data-posterid={poster.posterId}
                        data-htmlsrc={poster.htmlfile}
                        src={thumbimg}
                        data-share={settingsData.posterShare}
                        data-download={settingsData.posterDownload}
                        data-theme={poster.theme_label}
                        data-author={poster.author}
                        data-individualdownload={poster.allow_download}
                        data-totalviews={poster.viewCount}
                      >
                        {poster.title.replace(/(<([^>]+)>)/gi, "")}
                      </p>
                      <div className="postaut">
                        <img src={authorImage} />
                        <span>{poster.author}</span>
                      </div>

                      <div className="pbtngrp">
                        <div>
                          <span className="ptcl">
                            {settingsData.posterRating === "1" ? (
                              <>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <span>{poster.rating}</span>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="ptcl">
                            {settingsData.posterView === "1" ? (
                              <>
                                <img src="/images/seenicon.png" />
                                <span>{poster.viewCount}</span>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>

                        <div>
                          <span className="ptcl">
                            {settingsData.posterDownload === "1" ? (
                              <>
                                {poster.allow_download == 1 ? (
                                  <a
                                    onClick={() => {
                                      handleShow();
                                      setOPosterId(poster.posterId);
                                    }}
                                  >
                                    <img src="/images/downloadiconps.png" />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="ptcl shareCustomIcon">
                            {settingsData.posterShare === "1" ? (
                              <>
                                {/* <a href="">
												<img src="/images/shareps.png" />
											</a> */}

                                <div class="dropdown">
                                  <span>
                                    <img src="/images/shareps.png" />
                                  </span>
                                  <div class="dropdown-content">
                                    <ul id="share-list">
                                      <li class="inner-share-li">
                                        <FacebookShareButton
                                          url={posterShareLink}
                                          quote={poster.title}
                                          imageURL={thumbimg}
                                        >
                                          <FacebookIcon
                                            size={30}
                                          ></FacebookIcon>
                                        </FacebookShareButton>
                                      </li>
                                      <li class="inner-share-li">
                                        <TwitterShareButton
                                          url={posterShareLink}
                                          title={poster.title}
                                        >
                                          <TwitterIcon size={30}></TwitterIcon>
                                        </TwitterShareButton>
                                      </li>
                                      <li class="inner-share-li">
                                        <LinkedinShareButton
                                          url={posterShareLink}
                                          title={poster.title}
                                        >
                                          <LinkedinIcon size={30} />
                                        </LinkedinShareButton>
                                      </li>
                                      <li>
                                        <EmailShareButton
                                          url={posterShareLink}
                                          title={poster.title}
                                        >
                                          <EmailIcon size={30} />
                                        </EmailShareButton>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div class="demo"></div>
        </FadeIn>

        {/* Download Poster modal  */}
        <Modal className="dwnp-modal" show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img className="dp-icon" src="/images/downloadiconps.png" />
            <h2>Download Poster</h2>
            {showEmailAlert ? (
              <div
                className={"alert " + alertColor}
                id="sentEmailAlert"
                role="alert"
              >
                {alertText}
              </div>
            ) : (
              ""
            )}
            <div className="dp-from">
              <form>
                <div className="from-group">
                  <label>Your Name</label>
                  <input
                    type="text"
                    placeholder="Type your name"
                    className="form-control"
                    id="SEPersonName"
                  />
                  <input type="hidden" value={oPosterId} id="SEPosterId" />
                </div>
                <div className="from-group">
                  <label>Your Email Address</label>
                  <input
                    type="text"
                    placeholder="Type your email address"
                    className="form-control"
                    id="SEPersonEmail"
                  />
                </div>
                <button
                  type="button"
                  onClick={(e) => {
                    sendEmail(e);
                  }}
                >
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  Send Poster
                </button>
                <p>(Email delivery scheduled every 2 hours)</p>
              </form>
            </div>
          </Modal.Body>
        </Modal>

        {/* Download Poster modal  */}
      </div>

      {/* Share Modal */}

      <Share
        showShare={showShare}
        sharePosterTitle={oPosterTitle}
        handleShareClose={() => {
          setShowShare(false);
        }}
      ></Share>

      {/* End Share Modal */}

      {/* Contact Modal */}

      <Contact
        showContact={showContact}
        authorEmail={oAuthorEmail}
        handleContactClose={() => {
          setShowContact(false);
        }}
      ></Contact>

      {/* End Contact Modal */}

      {/* OVerview Modal*/}

      <div className="ovmodal" id="ovModal">
        <div className="container">
          <div className="ov-hed">
            <h2>Overview</h2>
            <button className="close" onClick={closeOverViewModal}>
              Close
            </button>
          </div>
          <div className="row ov-body">
            <div className="col-lg-8 col-md-12 col-sm-12 left-ovbody">
              <p>
                <span>Poster ID:</span>
                {/* {oPosterId} */}
                {oPaperId}
              </p>
              <p>
                <span>Poster Title:</span>
                {oPosterTitle}
              </p>
              <p>
                <span>Co-Author(s):</span>
                {oPosterCoAuthors}
              </p>
              <p>
                <span>Topic:</span>
                {oThemeName}
              </p>
              {/* <p>
					<span>Average Rating:</span>
					4.2
				</p> */}
              {oCustomData && oCustomData.length > 0
                ? oCustomData.map((cData) => {
                    return (
                      <p>
                        <span> {cData.label}</span>
                        {cData.value}
                      </p>
                    );
                  })
                : ""}
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="postaut">
                <img src={oAuthorImage} />
                <span>
                  <h2>{oAuthorName}</h2>
                  <p>{oAuthorBio}</p>
                  <button onClick={handleContactShow}>
                    <i class="fa fa-envelope"></i> Contact Author
                  </button>
                </span>
              </div>
            </div>
          </div>

          <div className="row ovposterrow">
            <div className="col-lg-6 col-md-12 col-sm-12 pr-3">
              <h2>
                Other Posters of {oAuthorName}
                {oPostersBySameAuthor && oPostersBySameAuthor.length > 0 ? (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem("openFilters", 1);
                      localStorage.removeItem("searchValue");
                      localStorage.removeItem("dateValue");
                      localStorage.setItem("authorValue", oAuthorName);
                      window.location.href =
                        "/posters?tid=all&a=" + oAuthorName;
                    }}
                  >
                    View all
                  </a>
                ) : (
                  ""
                )}
              </h2>
              <div className="posterrow ">
                {oPostersBySameAuthor && oPostersBySameAuthor.length > 0
                  ? oPostersBySameAuthor.map((BSAposter) => {
                      var BSauthorImage = "/images/profile.png";
                      if (
                        BSAposter.authorImage &&
                        BSAposter.authorImage != "" &&
                        BSAposter.authorImage != "profile.png"
                      ) {
                        BSauthorImage =
                          myConfig.externalFileUrl + BSAposter.authorImage;
                      }

                      var thumbimg =
                        myConfig.http + hostname + "/" + BSAposter.thumb;

                      // if (eventId == myConfig.s3EventId) { // pulling from S3
                      // 	var htmlURL =  myConfig.s3HostName + "" + BSAposter.htmlfile;
                      // } else {
                      if (!pdfExists) {
                        var htmlURL =
                          myConfig.http + hostname + "" + BSAposter.htmlfile;
                      }
                      //}
                      var posterLink = btoa(htmlURL);
                      var newUrl =
                        "/posters?tid=all" +
                        "&preview=" +
                        posterLink +
                        "&theme=" +
                        BSAposter.theme_label +
                        "&posterId=" +
                        BSAposter.posterId +
                        "&author=" +
                        BSAposter.author +
                        "&indiDownload=" +
                        BSAposter.allow_download +
                        "&tv=" +
                        BSAposter.viewCount;

                      return (
                        <div className="postercol">
                          <img
                            onClick={() => {
                              document.getElementById("getdata").src = "";
                              window.location.href = newUrl;
                            }}
                            src={thumbimg}
                          />
                          <div className="postconholder">
                            <p>
                              {BSAposter.title.replace(/(<([^>]+)>)/gi, "")}
                            </p>
                            <div className="postaut">
                              <img src={BSauthorImage} />
                              <span>{BSAposter.author}</span>
                            </div>

                            <div className="pbtngrp">
                              <div>
                                <span className="ptcl">
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <span>{BSAposter.rating}</span>
                                </span>
                                <span className="ptcl">
                                  <img src="/images/seenicon.png" />
                                  <span>{BSAposter.viewCount}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 pl-3">
              <h2>
                Related Posters
                {oSimilarPosters && oSimilarPosters.length > 0 ? (
                  <a
                    style={{ "text-decoration": "none" }}
                    href={"/posters?tid=" + oThemeId}
                  >
                    View all
                  </a>
                ) : (
                  ""
                )}
              </h2>
              <div className="posterrow">
                {oSimilarPosters && oSimilarPosters.length > 0
                  ? oSimilarPosters.map((Sposters) => {
                      var SPauthorImage = "/images/profile.png";
                      if (
                        Sposters.authorImage &&
                        Sposters.authorImage != "" &&
                        Sposters.authorImage != "profile.png"
                      ) {
                        SPauthorImage =
                          myConfig.externalFileUrl + Sposters.authorImage;
                      }
                      // if (eventId == myConfig.s3EventId) { // pulling from S3
                      // 	var htmlURL =  myConfig.s3HostName + "" + Sposters.htmlfile;
                      // } else {
                      if (!pdfExists) {
                        var htmlURL =
                          myConfig.http + hostname + "" + Sposters.htmlfile;
                      }
                      //}

                      var posterLink = btoa(htmlURL);
                      var thumbimg =
                        myConfig.http + hostname + "/" + Sposters.thumb;
                      var newUrl =
                        "/posters?tid=all" +
                        "&preview=" +
                        posterLink +
                        "&theme=" +
                        Sposters.theme_label +
                        "&posterId=" +
                        Sposters.posterId +
                        "&author=" +
                        Sposters.author +
                        "&indiDownload=" +
                        Sposters.allow_download +
                        "&tv=" +
                        Sposters.viewCount;

                      return (
                        <div className="postercol">
                          <img
                            onClick={() => {
                              document.getElementById("getdata").src = "";
                              // window.history.pushState({ }, '', '');
                              window.location.href = newUrl;
                            }}
                            src={thumbimg}
                            data-html={newUrl}
                          />

                          <div className="postconholder">
                            <p>{Sposters.title.replace(/(<([^>]+)>)/gi, "")}</p>
                            <div className="postaut">
                              <img src={SPauthorImage} />
                              <span>{Sposters.author}</span>
                            </div>

                            <div className="pbtngrp">
                              <div>
                                <span className="ptcl">
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <span>{Sposters.rating}</span>
                                </span>
                                <span className="ptcl">
                                  <img src="/images/seenicon.png" />
                                  <span>{Sposters.viewCount}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : "No related posters"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Overview modal */}

      {/* Abstract Modal */}
      <div className="ovmodal" id="abModal">
        <div className="container">
          <div className="ov-hed">
            <h2>Abstract</h2>
            <button className="close" onClick={closeAbstractModal}>
              Close
            </button>
          </div>
          <div className="row ov-body">
            {oAbstractData.type == "pdf" ? (
              <embed src={oAbstractData.data} className="embedAbstract" />
            ) : (
              ""
            )}

            {oAbstractData.type == "txt" ? <p>{oAbstractData.data}</p> : ""}
          </div>
        </div>
      </div>
      {/* Abstract Modal */}

      {/* Main Poster View Modal */}
      <div id="mask"></div>

      <div id="popup">
        <button onClick={closePreviewModal} className="closemask">
          <i class="las la-times"></i>
        </button>
        {!pdfExists ? (
          <div>
            <iframe
              id="getdata"
              className="innerdivsec"
              src=""
              ref={(el) => {
                pptPreviewRef.current = el;
                ppthandleResize();
              }}
            ></iframe>
          </div>
        ) : (
          <div
            ref={(el) => {
              pdfPreviewRef.current = el;
              handleResize();
            }}
          >
            <PosterPdf />
          </div>
        )}

        <div className="popfooter" ref={footerRef}>
          <div className="popflogo">
            {whitelabel.logoImage || whitelabel.brandName ? (
              <>
                {whitelabel.logoImage ? (
                  <img
                    src={myConfig.http + hostname + "/" + whitelabel.logoImage}
                  />
                ) : (
                  ""
                )}

                <span className="footerTextPoster">
                  {whitelabel.brandName ? <>{whitelabel.brandName}</> : ""}
                </span>
              </>
            ) : (
              <>
                <img src="/images/evexus-logo.png" alt="Powered By Evexus" />
              </>
            )}
          </div>
          <div className="popfrating">
            <div class="rate">
              {showPreviewPosterRating === "1" ? (
                <Rating
                  emptySymbol="fa fa-star-o fa-2x"
                  fullSymbol="fa fa-star fa-2x"
                  onClick={(e) => {
                    ratePoster(e);
                  }}
                />
              ) : (
                ""
              )}
              <input type="hidden" id="singlePosterViews" value={totalViews} />
            </div>
            {/* <p>Rate this poster</p> */}
          </div>
          <div className="popfmenu">
            {oAbstractData.type != "none" ? (
              <>
                <button
                  onClick={() => {
                    showAbstract();
                  }}
                >
                  <img src="/images/footermi-1.svg" />
                  <p>Abstract</p>
                </button>
                <input type="hidden" valie="" id="posterHiddenTitle" />
              </>
            ) : (
              ""
            )}
            <button onClick={handleContactShow}>
              <img src="/images/footermi-3.svg" />
              <p>Contact</p>
            </button>
            {showPreviewPosterDownload === "1" ? (
              <>
                {posterAllowDownload === "1" ? (
                  <button onClick={handleShow}>
                    <img src="/images/footermi-4.svg" />
                    <p>Download</p>
                  </button>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {showPreviewPosterShare === "1" ? (
              <button onClick={handleShareShow}>
                <img src="/images/footermi-5.svg" />
                <p>Share</p>
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => {
                showOverView();
              }}
            >
              <img src="/images/footermi-2.svg" />
              <p>Overview</p>
            </button>
            <button onClick={closePreviewModal}>
              <img src="/images/modal-close.svg" />
              <p>Close</p>
            </button>
          </div>
        </div>
      </div>
      {/* Main Poster View Modal */}
    </div>
  );
};

export default Poster;
