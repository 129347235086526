import React from 'react';
import {  Modal } from "react-bootstrap";
import $ from "jquery";
import { myConfig } from "../../../config";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, LinkedinIcon, LinkedinShareButton } from "react-share";

export default function Share({showShare, sharePosterTitle, handleShareClose}){

    var previewPosterShareLink = window.location.href;
    return(
        <Modal className="dwnp-modal" centered show={showShare} onHide={handleShareClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <h2>Share With</h2>
            <div className="">
                <FacebookShareButton 
                    className="imageShare"
                    url={previewPosterShareLink}
                    quote={sharePosterTitle}
                    imageURL={''}
                >
                    <FacebookIcon size={40}></FacebookIcon>
                </FacebookShareButton>

                <TwitterShareButton 
                    className="imageShare"
                    url={previewPosterShareLink}
                    title={sharePosterTitle}
                >
                    <TwitterIcon size={40}></TwitterIcon>
                </TwitterShareButton>
														
                <LinkedinShareButton
                    className="imageShare"
                    url={previewPosterShareLink}
                    title={sharePosterTitle}
                >
                    <LinkedinIcon size={40} />
                </LinkedinShareButton>

                <EmailShareButton
                    className="imageShare"
                    url={previewPosterShareLink}
                    title={sharePosterTitle}
                >
                    <EmailIcon size={40} />
                </EmailShareButton>

                {/* <img className="imageShare" src={process.env.PUBLIC_URL+"/images/ic_email_small.svg"} alt="Email"/>
                <img className="imageShare" src={process.env.PUBLIC_URL+"/images/ic_fb.svg"} alt="Facebook"/>
                <img className="imageShare" src={process.env.PUBLIC_URL+"/images/ic_li.svg"} alt="LinkedIn"/>
                <img className="imageShare" src={process.env.PUBLIC_URL+"/images/ic_tt.svg"} alt="Twitter"/> */}
            </div>
        </Modal.Body>
        </Modal>
    )
}