import * as actions from "../actions/actionTypes";

const eventSettingReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.SETEVENTSETTING:
      return {
        eventsettings: action.payload,
      };

    default:
      return state;
  }
};

export default eventSettingReducer;
