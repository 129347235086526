import styled from 'styled-components';
  
export const Heading = styled.h1`
   text-align: center;
   color: green;
`;
  
export const Content = styled.div`
   overflowY: scroll;
   height: 2500px;
`;
  
export const Button = styled.div`
   position: fixed;
   right: 30px;
   bottom: 150px;
   font-size: 30px;
   z-index: 1;
   cursor: pointer;
`;
