import * as actions from "../actions/actionTypes"

const themeReducer = (state = {}, action) => {
    switch(action.type){
        
        case actions.SETTHEMES:
            return {
                themes: action.payload
            };

        default:
            return state;
    }
}

export default themeReducer;