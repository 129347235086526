export const SETFILTERS = "setFilters";

export const SETAUTHORS = "setAuthors";

export const SETTHEMES = "setThemes";

export const SETPOSTERS = "setPosters";

export const SETPOSTERSCOUNT = "setPostersCount";

export const SETPOSTERREVIEWS = "setPostersReviews";

export const SETEVENTSETTING = "setEventSetting";

export const SETPROGRAMDATES = 'setProgramDates';