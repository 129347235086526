import { createStore, combineReducers, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer";
import logger from "redux-logger";
import { composeWithDevTools } from 'redux-devtools-extension';


const initialSate = {};

const store = createStore(combineReducers({

        rootReducer

    }), composeWithDevTools(applyMiddleware(logger))
);

export default store;