import React, { Component, useState, useReducer, useEffect } from "react";
import css from "./nav.module.scss";
import "./nav.css";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { chnageFilterData } from "../../actions/index";
import Select from "react-select";
import { Badge, Modal } from "react-bootstrap";
import axios from "axios";
import { myConfig } from "../../config";

const Nav = (props) => {
  var hostname = localStorage.getItem("hostname");
  var qs = require("qs");

  const location = useLocation();
  const path = location.pathname;
  const [isIndexPage, setIsIndexPage] = useState(path == "/" ? true : false);

  const dispatch = useDispatch();
  var authors = useSelector((state) => {
    if (state.rootReducer.authors.authors != undefined) {
      return state.rootReducer.authors.authors.authors;
    } else {
      const myTimeout = setTimeout(() => {
        window.location.reload();
      }, 4000);
      return {};
    }
  });
  var filterData = useSelector((state) => {
    return state.rootReducer.filters || {};
  });

  //console.log(2222222, filterData?.filters);

  const [authorList, setAuthorList] = useState([]);
  const [filtershow, filtersetShow] = useState(false);
  const filterhandleClose = () => {
    setRoom("Any room");
    setSession("Any session");
    filtersetShow(false);
  };
  const filterhandleShow = () => filtersetShow(true);

  const [date2, setDate2] = useState("");
  const date = localStorage.getItem("dateValue");
  const [room, setRoom] = useState("Any room");
  const [showDate, setShowDate] = useState("");
  const [showRoom, setShowRoom] = useState("Any room");
  const [roomData, setRoomData] = useState([]);
  const [session, setSession] = useState("Any session");
  const [sessionData, setSessionData] = useState([]);
  // console.log(121, date2, date, room, session);
  // console.log(120, localStorage.getItem("searchValue"));

  //   console.log(130,date2.toDateString().split(' ').slice(1).join(' '));
  const handleReset = () => {
    setRoom("Any room");
    setSession("Any session");
  };
  const handleModalSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("dateValue", date2);
    localStorage.setItem("showDate", showDate);
    localStorage.setItem("openFilters", 1);
    room !== "Any room"
      ? localStorage.setItem("room", room)
      : localStorage.removeItem("room");
    room !== "Any room"
      ? localStorage.setItem("showRoom", showRoom)
      : localStorage.removeItem("room");
    session !== "Any session"
      ? localStorage.setItem("session", session)
      : localStorage.removeItem("session");
    localStorage.removeItem("searchValue");
    // localStorage.removeItem('authorValue');
    const room2 = room !== "Any room" ? room : "";
    const session2 = session !== "Any session" ? session : "";
    window.location.href =
      "/posters?tid=all&d=" + date2 + "&r=" + room2 + "&n=" + session2;
    const filteringData = {
      date2,
      room2,
      session2,
    };

    changeFilter(filteringData, "filter", e);
  };

  //   useEffect(() => {
  const onDateClickApply = (date2) => {
    var filterdata2 = "";
    filterdata2 = qs.stringify({
      date: date2,
      hostname: hostname,
      //page: 0 //pageNumber
    });
    axios({
      method: "POST",
      url: myConfig.apiUrl + "getRooms.php",
      data: filterdata2,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // Success
        let data = response.data;
        setRoomData(data);
        // console.log(150,response.data);

        // let rawposters = [];
        // if (response.data.posters.length > 0) {
        // 	rawposters = response.data.posters;
        // }
        // setPosters(rawposters);
        // setIsNotLoaded(false);
        // setPosterCount(response.data.totalPosterCount);
        // setViewCountIncreased(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const onRoomClickApply = (date2, room) => {
    var filterdata3 = "";
    filterdata3 = qs.stringify({
      date: date2,
      room_id: room,
      hostname: hostname,
      //page: 0 //pageNumber
    });
    axios({
      method: "POST",
      url: myConfig.apiUrl + "getSessions.php",
      data: filterdata3,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // Success
        // console.log(151,response.data);
        setSessionData(response.data);
        // let rawposters = [];
        // if (response.data.posters.length > 0) {
        // 	rawposters = response.data.posters;
        // }
        // setPosters(rawposters);
        // setIsNotLoaded(false);
        // setPosterCount(response.data.totalPosterCount);
        // setViewCountIncreased(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  // if(date2){
  // 	onDateClickApply()
  // }

  // if(date2 && room!=='Any room'){
  // 	onRoomClickApply()
  // }
  // }, [])

  // console.log(150,roomData)

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  var theme_id = params.get("tid");

  // Looping authors for showing name
  var data = {};
  $("#authors option").each(function (i, el) {
    data[$(el).data("value")] = $(el).val();
  });

  // Looping program dates
  var programDates = useSelector((state) => {
    if (state.rootReducer.authors.authors != undefined) {
      return state.rootReducer.programdates.programdates.programDates;
    } else {
      return {};
    }
  });
  // console.log(programDates);

  // Program dates
  var options = [];
  for (let i = 0; i < programDates.length; i++) {
    let rawdate = new Date(programDates[i]["date"]);
    options.push({
      value: programDates[i]["date"],
      label: rawdate.toDateString().split(" ").slice(1).join(" "),
    });
    // console.log(130,programDates[i]["date"]);
  }
  // End looping program dates
  // authors.map(auth=>
  // console.log(122,auth.author_name))
  // Authors
  var authorsDrops = [];
  for (let i = 0; i < authors.length; i++) {
    authorsDrops.push({
      value: authors[i]["author_name"],
      label: authors[i]["author_name"],
    });
  }

  var roomDrops = [];
  roomData.map((room) => {
    roomDrops.push({ value: `${room.room_id}`, label: `${room.name}` });
  });

  var sessionDrops = [];
  sessionData.map((session) => {
    sessionDrops.push({ value: `${session.label}`, label: `${session.label}` });
  });

  // var searchValue = [];
  // for (let i = 0; i < authors.length; i++) {
  // 	searchValue.push({ value: authors[i]["author_name"], label: authors[i]["author_name"] });
  // }

  // Change Filter Display Status
  let openFilters = localStorage.getItem("openFilters") == 1 ? true : false;

  const [advanceFilterDisplay, setAdvanceFilterDisplay] = useState(openFilters);
  const changeDisplayStatus = () => {
    if (advanceFilterDisplay) {
      setAdvanceFilterDisplay(false);
      return;
    } else {
      setAdvanceFilterDisplay(true);
      return;
    }
  };
  var searchPlaceHolder = "Search for title or @author name";
  if (props.posterKeywordsSearch == 1) {
    searchPlaceHolder = "Search for title, keywords or @author name";
  }

  // Local storage
  if (localStorage.getItem("searchValue")) {
    var searchValue = localStorage.getItem("searchValue");
  } else {
    var searchValue = searchPlaceHolder;
  }

  if (localStorage.getItem("dateValue")) {
    var dateValue = localStorage.getItem("dateValue");
  } else {
    var dateValue = "Select Date";
  }
  var authorValue = localStorage.getItem("authorValue") ?? "Select Author";

  const changeFilter = (data, type, event) => {
    var posterDate = "";
    var search = "";
    var author = "";

    // var posterRoom = "";
    if (filterData.filters) {
      posterDate = filterData.filters.posterDate;
      search = filterData.filters.searchData;
      author = filterData.filters.author;
      //   console.log(99999, filterData.filters);
    }

    if (type == "author") {
      localStorage.setItem("openFilters", 1);
      localStorage.removeItem("searchValue");
      localStorage.removeItem("dateValue");
      var author = data;
      var search = "";
      if (isIndexPage || theme_id != "all") {
        localStorage.setItem("authorValue", author);
        window.location.href = "/posters?tid=all&a=" + author;
      }
    } else if (type == "search") {
      var search = data;
      var author = "";
      localStorage.removeItem("dateValue");
      localStorage.removeItem("authorValue");
      if (isIndexPage || theme_id != "all") {
        localStorage.setItem("searchValue", search);
        window.location.href = "/posters?tid=all&s=" + search;
      }
    } else if (type == "filter") {
      var posterDate = data.date2;
      var posterRoom = data.room2;
      var posterSessionName = data.session2;
      //   var author = "";
      //   localStorage.removeItem("dateValue");
      //   localStorage.removeItem("authorValue");
      //   if (isIndexPage || theme_id != "all") {
      //     localStorage.setItem("searchValue", search);
      //     window.location.href = "/posters?tid=all&s=" + search;
      //   }
    }
    // else if (type == "posterdate") {
    // 	posterDate = data;
    // 	if (isIndexPage) {
    // 		window.location.href = "/posters?tid=all&d=" + posterDate;
    // 	}
    // }
    dispatch(
      chnageFilterData(
        search,
        author,
        posterDate,
        posterRoom,
        posterSessionName
      )
    );
  };

  var homeLink = "/";
  if (props.homepage_version == 1) {
    if (window.location.pathname == "/") {
      window.location.href = "/posters?tid=all";
    }
    homeLink = "/posters?tid=all";
  }
  var stickyNavStyle = {};
  if (props.navPosition == 1) {
    stickyNavStyle = { position: "sticky", bottom: 0 };
  }
  //console.log(459, filterData?.filters);
  if (
    filterData?.filters === undefined
    // filterData?.filters?.posterDate === null
    // && filterData?.filters?.searchData === null
  ) {
    localStorage.removeItem("openFilters");
    // localStorage.removeItem("dateValue");
    // localStorage.removeItem("authorValue");
    // localStorage.removeItem("searchvalue");
    // localStorage.removeItem("room");
    // localStorage.removeItem("session");
  }
  //nav_navRoot__2axLY
  return (
    <div className={css.navRoot} style={stickyNavStyle}>
      <div>
        <div
          className={css.headnav}
          style={{ background: props.backgroundColor }}
        >
          <span className={css.lfsp}>
            {/* <div className={`${css.backDp} dropdown`}>
              <button
                className={`${css.btndp} dropdown-toggle`}
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                Return to
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a className="dropdown-item" href="#">
                    Lobby
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Agenda
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Exhibition Hall
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Video Session
                  </a>
                </li>
              </ul>
            </div> */}
            <a
              className={css.hmbtn}
              href={homeLink}
              onClick={(e) => {
                localStorage.removeItem("openFilters");
                localStorage.removeItem("dateValue");
                localStorage.removeItem("authorValue");
                localStorage.removeItem("searchvalue");
                localStorage.removeItem("room");
                localStorage.removeItem("session");
                window.location.reload();
              }}
            >
              <i className="fa fa-home" aria-hidden="true"></i>
              <span>Home</span>
            </a>
          </span>
          <span className={css.rssp}>
            <div className={css.hedsrch}>
              <input
                type="text"
                // options={authorsDrops}
                placeholder={searchValue}
                id="searchdata"
                onSelect={() => {
                  document.getElementById("searchdata").placeholder =
                    searchPlaceHolder;
                  // localStorage.setItem('searchValue',  searchPlaceHolder);
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    let searchdata = $("#searchdata").val();

                    changeFilter(searchdata, "search", event);
                    localStorage.removeItem("dateValue");
                    localStorage.removeItem("authorValue");
                    localStorage.removeItem("room");
                    localStorage.removeItem("session");
                    localStorage.setItem("searchValue", searchdata);
                    // localStorage.setItem("openFilters", 1);
                    window.location.href = "/posters?tid=all&s=" + searchdata;
                  }
                }}
                onChange={(e) => {
                  const input = e.target.value.slice(1).toLowerCase();
                  setAuthorList(
                    authors.filter((author) =>
                      author.author_name.toLowerCase().includes(input)
                    )
                  );
                }}
              />
              <div
                style={
                  $("#searchdata").val()?.includes("@")
                    ? { display: "block", position: "absolute", width: "100%" }
                    : { display: "none" }
                }
              >
                <ul className="search-suggestion-box">
                  {authorList.map((auth) => (
                    <li
                      className="suggestion-value"
                      onClick={(e, value = `${auth.author_name}`) => {
                        $("#searchdata").val(value);
                        let searchdata = `${auth.author_name}`;
                        changeFilter(`${auth.author_name}`, "search", e);
                        localStorage.removeItem("dateValue");
                        localStorage.removeItem("authorValue");
                        localStorage.removeItem("room");
                        localStorage.removeItem("session");
                        localStorage.setItem("searchValue", searchdata);
                        // localStorage.setItem("openFilters", 1);
                        window.location.href =
                          "/posters?tid=all&s=" + searchdata;
                      }}
                    >
                      {auth.author_name}
                    </li>
                  ))}
                </ul>
              </div>
              <button
                type="button"
                onClick={(event) => {
                  let searchdata = $("#searchdata").val();

                  changeFilter(searchdata, "search", event);
                  localStorage.removeItem("dateValue");
                  localStorage.removeItem("authorValue");
                  localStorage.removeItem("room");
                  localStorage.removeItem("session");
                  localStorage.setItem("searchValue", searchdata);
                  // localStorage.setItem("openFilters", 1);
                  window.location.href = "/posters?tid=all&s=" + searchdata;
                }}
              >
                <i className="las la-search"></i>
              </button>
            </div>

            {/* <a
              className={`showhide ${css.filterbtn} dropdown-toggle`}
              onClick={changeDisplayStatus}
              style={{ cursor: "pointer" }}
            >
              <i className="las la-filter"></i>
              <span>Filters </span>
            </a> */}
            { (programDates.length > 0) ? 
            <button onClick={filterhandleShow} className={css.filterbtn}>
              <i className="las la-filter"></i>
              <span>Filters </span>
            </button>
             : <span></span>}
          </span>
        </div>
        <Modal
          className="dwnp-modal filtermodal"
          centered
          show={filtershow}
          onHide={filterhandleClose}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="dp-from">
              <form id="filterForm" onSubmit={handleModalSubmit}>
                <div className="from-group">
                  <label>Date of Presentation</label>

                  <Select
                    placeholder={dateValue}
                    options={options}
                    isSearchable={false}
                    // className="date"
                    onChange={(event) => {
                      props.changePageCount(1);
                      let rawDT = new Date(event.value);
                      let rawDT2 = rawDT
                        .toDateString()
                        .split(" ")
                        .slice(1)
                        .join(" ");

                      if (rawDT) {
                        onDateClickApply(rawDT);
                      }
                      setDate2(event.value);
                      setShowDate(event.label);
                      // localStorage.setItem('dateValue', rawDT.toDateString().split(' ').slice(1).join(' '));
                      // localStorage.setItem('openFilters', 1);
                      // localStorage.removeItem('searchValue');
                      // localStorage.removeItem('authorValue');
                      // window.location.href = "/posters?d=" + event.value;
                    }}
                    className="modselect2 tanmoy"
                    styles={{ color: "white" }}
                  />
                </div>
                <div className="from-group">
                  <label>Room</label>
                  <Select
                    isSearchable={false}
                    onChange={(event) => {
                      // console.log(191, date2, event.value);
                      if (date2 && event.value) {
                        // let date=document.getElementById("date").value
                        onRoomClickApply(date2, event.value);
                      }
                      setRoom(event.value);
                      setShowRoom(event.label);
                    }}
                    options={roomDrops}
                    // isDisabled={!date2?true:false}
                    placeholder={room}
                  />

                  {/* <select className="form-control" onChange={(e)=>setRoom(e.target.value)} disabled={!date2?true:false}>
                    <option selected >Any room</option>
                    {roomData.map(data=>
					<option>{data.room_id}</option>)}
                    <option>c</option>
                    <option>d</option>
                    <option>e</option>
                  </select> */}
                </div>

                <div className="from-group">
                  <label>Session Name</label>

                  <Select
                    isSearchable={false}
                    onChange={(event) => {
                      setSession(event.value);
                    }}
                    options={sessionDrops}
                    // isDisabled={room==='Any room'?true:false}
                    placeholder={session}
                  />

                  {/* <select className="form-control" onChange={(e)=>setSession(e.target.value)} disabled={room==='Any room'?true:false}>
                    <option selected>Any session</option>
                    {sessionData.map(data=>
					<option>{data.label}</option>)}
                    <option>b</option>
                    <option>c</option>
                    <option>d</option>
                    <option>e</option>
                  </select> */}
                </div>

                <div className="modfooter">
                  <button type="button" className="filreset" onClick={filterhandleClose}>
                    Cancel
                  </button>
                  <button type="submit">Apply</button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
        {advanceFilterDisplay ? (
          <>
            <div
              className={`${css.filternav}`}
              style={{ background: props.backgroundColor }}
            >
              <div className={css.fliterright}>
                <div className={`${css.filternavitem1}`}>
                  {localStorage.getItem("dateValue") && (
                    <Badge
                      pill
                      bg="primary"
                      // style={{
                      //   cursor: "pointer",
                      //   backgroundColor: "#CDD5FF",
                      //   color: "#1F1F1F",
                      //   borderRadius: "20px",
                      // }}
                      className="mx-2 ps-3 chips"
                    >
                      {localStorage.getItem("showDate")}
                      <div
                        className={`${css.filternavitem3}`}
                        style={{ cursor: "pointer", display: "contents" }}
                        onClick={() => {
                          localStorage.removeItem("openFilters");
                          localStorage.removeItem("dateValue");
                          localStorage.removeItem("room");
                          localStorage.removeItem("session");
                          window.location.reload();
                        }}
                      >
                        <i
                          className="las la-times"
                          style={{ color: "#FF0000" }}
                        ></i>
                      </div>
                    </Badge>
                  )}
                  {localStorage.getItem("room") && (
                    <Badge
                      pill
                      bg="primary"
                      // style={{
                      //   cursor: "pointer",
                      //   backgroundColor: "#CDD5FF",
                      //   color: "#1F1F1F",
                      //   borderRadius: "20px",
                      // }}
                      className="mx-2 ps-3 chips"
                    >
                      {localStorage.getItem("showRoom")}

                    <div
                        className={`${css.filternavitem3}`}
                        style={{ cursor: "pointer", display: "contents" }}
                        onClick={() => {
                          if (!localStorage.getItem("dateValue")) {
                            localStorage.removeItem("openFilters");
                          }
                          localStorage.removeItem("room");
                          localStorage.removeItem("session");
                          localStorage.setItem("openFilters", 1);
                          // dispatch(
                          //   chnageFilterData(
                          //     null,
                          //     null,
                          //     localStorage.getItem("dateValue"),
                          //     null,
                          //     null
                          //   )
                          // );
                          window.location.href =
                            "/posters?tid=all&d=" +
                            localStorage.getItem("dateValue");

                          // window.location.reload();
                        }}
                    >
                        <i
                          className="las la-times"
                          style={{ color: "#FF0000" }}
                        ></i>
                      </div>
                    </Badge>
                  )}
                  {localStorage.getItem("session") && (
                   
                  <Badge pill bg="primary" className="mx-2 ps-3 chips">
                      {localStorage.getItem("session").length > 30
                        ? localStorage.getItem("session")?.slice(0, 30) + "..."
                        : localStorage.getItem("session")}
                      <div
                        className={`${css.filternavitem3}`}
                        style={{
                          cursor: "pointer",
                          display: "contents",
                          color: "#1F1F1F",
                        }}
                        onClick={() => {
                          if (
                            !(
                              localStorage.getItem("dateValue") ||
                              localStorage.getItem("room")
                            )
                          ) {
                            localStorage.removeItem("openFilters");
                          }
                          localStorage.removeItem("session");
                          localStorage.setItem("openFilters", 1);
                          window.location.href =
                            "/posters?tid=all&d=" +
                            localStorage.getItem("dateValue") +
                            "&r=" +
                            localStorage.getItem("room");
                          // window.location.reload();
                        }}
                      >
                        <i
                          className="las la-times"
                          style={{ color: "#FF0000" }}
                        ></i>
                      </div>
                    </Badge>
                  )}
                  {localStorage.getItem("searchValue") && (
                    <Badge
                      pill
                      bg="primary"
                      // style={{
                      //   cursor: "pointer",
                      //   backgroundColor: "#CDD5FF",
                      //   color: "#1F1F1F",
                      //   borderRadius: "20px",
                      // }}
                      className="mx-2 ps-3 chips"
                    >
                      {localStorage.getItem("searchValue")}

                      <div
                        className={`${css.filternavitem3}`}
                        style={{
                          cursor: "pointer",
                          display: "contents",
                          color: "#1F1F1F",
                        }}
                        onClick={() => {
                          localStorage.removeItem("openFilters");
                          localStorage.removeItem("searchValue");
                          window.location.reload();
                        }}
                      >
                        <i
                          className="las la-times"
                          style={{ color: "#FF0000" }}
                        ></i>
                      </div>
                    </Badge>
                  )}
                </div>

                    <div
                      className={`${css.filternavitem3}`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.removeItem("openFilters");
                        localStorage.removeItem("dateValue");
                        localStorage.removeItem("authorValue");
                        localStorage.removeItem("searchvalue");
                        localStorage.removeItem("room");
                        localStorage.removeItem("session");
                        window.location.reload();
                      }}
                    >
                      <i className="las la-times"></i>
                      Clear all filters
                    </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {/* Filter Section */}
        {/* {advanceFilterDisplay ? (
          <>
            <div
              className={`${css.filternav}`}
              style={{ background: props.backgroundColor }}
            > */}

        {/* <div className={css.filternavitemnull}></div> */}

        {/* <div className={css.fliterright}>
                <div className={`${css.filternavitem1}`}>
                  <p>Date of Presentation</p>
                  <div className=""> */}
        {/* Date Picker Code */}

        {/* <Select
						placeholder={dateValue}
						options={options}
						onChange={(event) => {
							props.changePageCount(1);
							let rawDT = new Date(event.value)
							localStorage.setItem('dateValue', rawDT.toDateString().split(' ').slice(1).join(' '));
							localStorage.setItem('openFilters', 1);
							localStorage.removeItem('searchValue');
							localStorage.removeItem('authorValue');
							window.location.href = "/posters?d=" + event.value;
						}}
						className="modselect2"
						styles={{ color: "white" }}
					/>
                  </div>
                </div>

                <div className={`${css.filternavitem2}`}>
					<p>Event Authors</p>
					<div className="">
						<Select
							options={authorsDrops}
							placeholder={authorValue}
							onChange={(event) => {
									props.changePageCount(1);
									localStorage.setItem('pageCount', 1);
									changeFilter(event.value, "author");
								}}
							className="modselect2 abhra"
							styles={{ color: "white" }}
						/>
					</div>
                </div>
                <div
                  className={`${css.filternavitem3}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
						localStorage.removeItem('openFilters');
						localStorage.removeItem('dateValue');
						localStorage.removeItem('authorValue');
						localStorage.removeItem('searchvalue');
                    	window.location.reload();
                  }}
                >
                  <i className="las la-times"></i> */}

        {/* couldn't take the normal font(pro only)*/}

        {/* Clear all filters
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
};

export default Nav;
